@import "~react-image-gallery/styles/css/image-gallery.css";


.backgroundImageStart {
    background-image: url("https://svebar.de/new/images/kandel.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 40%;
}


.appHeader {
    width: 100%;
    height: 120px;
    background-image: url("https://svebar.de/new/images/header.jpg");
    background-size: cover;
    display: flex;
    align-items: center;
}


.bigBackgroundImageLogo {
    max-width: 100%;
    height: auto;
}


.bigBackgroundImageWrapper {
    margin-top: -40%;
    padding-left: 60px;
    padding-top: 20px;
}


.floatLeft {
    float: left;
}


.floatRight {
    float: right;
}

.widhtAndHeight60 {
    width: 60px;
    height: 60px;
}


.scalableImage {
    max-width: 100%;
    height: auto;
}


.scalableImage2 {
    max-width: 500px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


.center1000 {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}


.center800 {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}


.padding10 {
    padding: 10px;
}

.marginTop40 {
    margin-top: 40px;
}

.marginTop20 {
    margin-top: 20px;
}

.marginTop80 {
    margin-top: 80px;
}

.marginBottom80 {
    margin-bottom: 80px;
}

.marginBottom20 {
    margin-bottom: 20px;
}

.marginLeft40 {
    margin-left: 40px;
}

.marginLeft20 {
    margin-left: 20px;
}

.marginRight20 {
    margin-right: 20px;
}

.marginRight10 {
    margin-right: 10px;
}

.font48LightBlue {
    font-weight: bold;
    font-size: 48px;
    color: lightblue;
}

.font32DodgerBlue {
    font-weight: bold;
    font-size: 32px;
    color: DodgerBlue;
}

.font48DodgerBlue {
    font-weight: bold;
    font-size: 48px;
    color: DodgerBlue;
}

.pointer {
    cursor: pointer;
}

.font32Black {
    font-size: 32px;
    color: black;
}

.centeredText100PercentWidth {
    text-align: center;
    width: 100%;
}

.statisticBox {
    border-radius: 10px;
    margin-right: 10px;
    background-color: #1e90ff;
    padding: 20px;
    float: left;
    width: 200px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
}

.statisticBox:hover {
    background-color: #4169e1;
}

.font80BoldWhite {
    font-size: 80px;
    color: white;
    font-weight: bold;
}

.font32BoldWhite {
    font-size: 32px;
    color: white;
    font-weight: bold;
}

.font18BoldBlack {
    font-size: 18px;
    color: black;
    font-weight: bold;
}

.font48BoldDodgerBlue {
    font-size: 48px;
    color: DodgerBlue;
    font-weight: bold;
}


.downloadButton {
    background-color: DodgerBlue;
    border: none;
    color: white;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 20px;
    margin-left: 30px;
}

/* Darker background on mouse-over */
.downloadButton:hover {
    background-color: RoyalBlue;
}


.inputField {
    width: 300px;
    height: 42px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.3);
    transition: 0.3s all;
}

.inputField input {
    width: 100%;
    height: 42px;
    position: relative;
    padding: 0px 16px;
    border: 1px solid black;
    border-radius: 4px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: white;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out;
    -webkit-appearance: none;
}

.inputField input:focus {
    background-color: lightblue;
}


.sortGalleries {
    float: right;
    margin-top: 10px;
    margin-right: 20px;
}

.sortGalleries input {
    width: 150px;
    height: 42px;
    padding: 0px 16px;
    border: 1px solid black;
    border-radius: 4px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: white;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out;
    -webkit-appearance: none;
}


.validationText {
    font-size: 12px;
    color: red;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.newsPanel {
    display: grid;
    grid-template-columns: auto 20px auto 1fr;
    grid-template-areas: 'icon . text .';
    border-radius: 5px;
    background-color: DodgerBlue;
    padding: 5px;
    width: 80%;
    margin-bottom: 10px;
    color: white;
    cursor: pointer;
}

.iconArea {
    grid-area: icon;
}

.textArea {
    grid-area: text;
    width: 100%;
}


.newsPanel:hover {
    background-color: RoyalBlue;
}


.statisticCircle {
    width: 250px;
    height: 250px;
}

.statisticBarChart {
    max-width: 800px;
    max-height: 400px;
}

.gallery-box {
    background-color: DodgerBlue;
    width: 190px;
    height: 210px;
    padding: 10px;
    text-align: center;
    float: left;
    font-size: 22px;
    color: white;
    margin-left: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    cursor: pointer;
}

.gallery-box:hover {
    background-color: RoyalBlue;
}


.tile {
    margin: 4px;
    float: left;
    cursor: pointer;
}

.tile img {
    box-shadow: 0 1px 1px 2px rgba(0, 0, 0, .15);
    border-radius: 10px;
    width: 100%;
}


/* menu-icon animated  starts here*/

.container {
    margin-right: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    float: right;
    position: relative;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.2s;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

.containerContent {

    position: absolute;
    z-index: 1;
    left: -130px;
    padding: 10px;
    background-color: #f9f9f9;
    max-width: 300px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    display: none;
}

.containerContent div {
    color: black;
    padding: 12px 16px;
}

/* Change color of dropdown links on hover */
.containerContent div:hover {background-color: #f1f1f1}

.show {
    display: block;
}




@media only screen and (max-width: 800px) {
    .appHeader {
        height: 100px;
    }

    .statisticBox {
        margin-left: 20px;
    }

    .newsPanel {
        margin-left: 20px;
    }
}

@media only screen and (max-width: 600px) {
    .appHeader {
        height: 60px;
    }

    .bigBackgroundImageLogo {
        max-width: 180px;
        height: auto;
    }

    .bigBackgroundImageWrapper {
        padding-top: 5px;
    }
}